<template>
	<div class="content">
		<ImageBanner :image="require(`@/assets/img_new/uslugi.jpg`)" class="mb120"></ImageBanner>
		<InfoBlocks :content="development" class="mb120"></InfoBlocks>
		<InfoBlocks :content="mobileDev" class="mb120" :sliderInMobile="true"></InfoBlocks>
		<InfoBlocks :content="support" class="mb120" :sliderInMobile="true"></InfoBlocks>
		<OutStuff :content="outStuff" class="mb120"></OutStuff>
		<Technologies :content="technologies" class="mb120"></Technologies>
		<section class="fullPageSectionContainer setMenuColor" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type="2"></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: 'Услуги веб-студии | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Услуги веб-студии Артрокетс в Казани. Профессиональная разработка сайтов, техническая поддержка и поисковое продвижение, а также внедрение CRM 1С Битрикс. Заполните форму на сайте, и мы вам перезвоним!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Услуги веб-студии | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Услуги веб-студии Артрокетс в Казани. Профессиональная разработка сайтов, техническая поддержка и поисковое продвижение, а также внедрение CRM 1С Битрикс. Заполните форму на сайте, и мы вам перезвоним!' },
			],
		},
		data: () => ({
			development: {
				title: "Разработка",
				description: "Запускаем интернет-проекты от простых с собственной изюминкой, до технически сложных и крупных.",
				link: "/uslugi/razrabotka/",
				linkText: "Подробнее",
				items: [
					{
						title: "Корпоративные сайты",
						link: "/uslugi/korp-site/",
						projects: [
							'<span class="icon-kaplife"></span>',
							'<span class="icon-abd-name"></span>',
							'<img src="'+require("@/assets/img_new/uslugi/rgs-zhizn.png")+'" class="img_dark lg"><img src="'+require("@/assets/img_new/uslugi/rgs-zhizn-hover.png")+'" class="img_light lg">',
							'<span class="icon-mpkabel"></span>',
							'<img src="'+require("@/assets/img_new/uslugi/rgs-med.png")+'" class="img_dark lg"><img src="'+require("@/assets/img_new/uslugi/rgs-med-hover.png")+'" class="img_light lg">',
							'<span class="icon-centrserdce lg"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
						]
					},{
						title: "Интернет-магазины",
						link: "/uslugi/internet-magazin/",
						projects: [
							'<span class="icon-dymov sm"></span>',
							'<img src="'+require("@/assets/img_new/uslugi/packs2.png")+'" class="img_dark"><img src="'+require("@/assets/img_new/uslugi/packs-hover2.png")+'" class="img_light">',
							'<span class="icon-savage sm"></span>',
							'<span class="icon-technorosst2"></span>',
							'<span class="icon-citystar"></span>',
							'<span class="icon-medar lg"><span class="path1"></span><span class="path2"></span></span>'
						]
					},{
						title: "Личные кабинеты",
						projects: [
							'<span class="icon-kaplife"></span>',
							'<img src="'+require("@/assets/img_new/uslugi/energotransit.png")+'" class="img_dark lg"><img src="'+require("@/assets/img_new/uslugi/energotransit-hover.png")+'" class="img_light lg">',
							'<span class="icon-isend"></span>',
						]
					},{
						title: "Web-сервисы и highload-проекты",
						projects: [
							'<img src="'+require("@/assets/img_new/uslugi/tehnika.png")+'" class="img_dark lg"><img src="'+require("@/assets/img_new/uslugi/tehnika-hover.png")+'" class="img_light lg">',
							'<span class="icon-isend"></span>',
						]
					},{
						title: "Сайты на 1С-Битрикс",
						projects: [
							'<span class="icon-kaplife"></span>',
							'<span class="icon-abd-name"></span>',
							'<img src="'+require("@/assets/img_new/uslugi/rgs-zhizn.png")+'" class="img_dark lg"><img src="'+require("@/assets/img_new/uslugi/rgs-zhizn-hover.png")+'" class="img_light lg">',
							'<span class="icon-dymov sm"></span>',
							'<img src="'+require("@/assets/img_new/uslugi/rgs-med.png")+'" class="img_dark lg"><img src="'+require("@/assets/img_new/uslugi/rgs-med-hover.png")+'" class="img_light lg">',
							'<span class="icon-centrserdce lg"></span>',
							'<span class="icon-mpkabel"></span>',
							'<span class="icon-technorosst2"></span>',
							'<span class="icon-medar lg"><span class="path1"></span><span class="path2"></span></span>',
							'<img src="'+require("@/assets/img_new/uslugi/packs2.png")+'" class="img_dark"><img src="'+require("@/assets/img_new/uslugi/packs-hover2.png")+'" class="img_light">',
							'<span class="icon-savage sm"></span>',
							'<span class="icon-citystar"></span>',
						]
					},{
						title: "MVP-проект",
						projects: [
							'<span class="icon-isend"></span>',
							'<span class="icon-dymov sm"></span>',
							'<img src="'+require("@/assets/img_new/uslugi/tehnika.png")+'" class="img_dark lg"><img src="'+require("@/assets/img_new/uslugi/tehnika-hover.png")+'" class="img_light lg">',
							'<span class="icon-abd-name"></span>',
						]
					}
				]
			},
			mobileDev: {
				title: "Разработка мобильных<br> приложений",
				description: "Создаем нативные Android, iOS и кроссплатформенные приложения.",
				// link: "/uslugi/support/",
				// linkText: "Подробнее",
				items: [
					{
						title: "Нативные Android, iOS приложения",
						description: "Создание мобильных приложений на Kotlin и Swift."
					},{
						title: "Кроссплатформенные приложения",
						description: "Создание мобильных приложений на Flutter."
					}
				]
			},
			support: {
				title: "Поддержка",
				description: "Развиваем и дорабатываем интернет-проекты. Обеспечиваем бесперебойную работу ресурса.",
				link: "/uslugi/support/",
				linkText: "Подробнее",
				items: [
					{
						title: "Поддержка серверов и баз данных",
						description: "Конфигурирование веб-серверов и обеспечение сохранности данных."
					},{
						title: "Поддержка по SLA",
						description: "Оперативное реагирование в случае перебоя в работе ресурса."
					},{
						title: "Любые доработки по дизайну, front-end и back-end",
						description: "Доработка проекта по фиксированным тарифам."
					}
				]
			},
			outStuff: {
				title: "Аутстафф",
				description: "Предоставляем команду разработчиков и тестировщиков, которая будет работать под вашим управлением.",
				items: [
					{
						title: "Front-end разработчики",
					},{
						title: "Back-end разработчики",
					},{
						title: "Full-stack разработчики",
					},{
						title: "Web дизайнеры",
					},{
						title: "QA-тестировщики",
					}
				],
				itemsDescription: "Количество специалистов, готовых к аутстафф постоянно меняется. CV и рейты предоставляются по запросу.",
				reviews: [
					{
						logo: '<span class="icon-kaplife"></span>',
						text: "Артрокетс оперативно предоставил нам недостающих специалистов для разработки внутреннего сервиса компании. Благодаря данному усилению, мы успешно завершили запланированные работы в срок.",
						author: {
							img: require(`@/assets/img_new/outstuff/3.jpg`),
							name: "Наталья Шмелева",
							description: "Директор диджитал проектов"
						}
					}
				]
			},
			technologies: {
				title: "Технологии",
				desc: "В разработке мы используем различный stack технологий и методологий разработки, которые подбираются для каждого проекта индивидуально.",
				items: [
					{
						title: "Front-end",
						technologies: [
							"React",
							"Angular",
							"Vue",
							"JQuery"
						]
					},{
						title: "Back-end",
						technologies: [
							"Php",
							"Bitrix",
							"Python",
							"Yii2",
						]
					},{
						title: "Management",
						technologies: [
							"SCRUM",
							"Agile",
							"Waterfall"
						]
					},{
						title: "Mobile",
						technologies: [
							"Swift",
							"Kotlin",
							"Flutter"
						]
					}
				]
			},
		}),
		components: {
			ImageBanner: () => import('@/components/new/ImageBanner'),
			InfoBlocks: () => import('@/components/new/InfoBlocks'),
			OutStuff: () => import('@/components/new/OutStuff'),
			Technologies: () => import('@/components/new/Technologies'),
			Feedback: () => import('@/components/new//Forms/Feedback')
		}
	}
</script>